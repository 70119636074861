import { template as template_64ebb7e8f0df406ea20c43b51c06b4e0 } from "@ember/template-compiler";
const FKLabel = template_64ebb7e8f0df406ea20c43b51c06b4e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_a721bdbf9acd46978ce2b33631e1af39 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a721bdbf9acd46978ce2b33631e1af39(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_445a2e88642641969c574c285207f9ad } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_445a2e88642641969c574c285207f9ad(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
